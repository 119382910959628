.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blackLink {
  color: black !important;
}

.whiteLink {
  color: white !important;
}

.title {
  font-family: 'Times New Roman', Times, serif;
  font-size: 24px;
}

.subtitle {
  font-size: 14px;
  color: gray;
}

.About {
  text-align: center;
}